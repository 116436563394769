"use client"

import clsx from "clsx";
import Style from "./Menu.module.scss";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import createCategoryURL from "@/lib/utils/createCategoryURL";
import { IconClose } from "@/icons/IconClose";
import Backdrop from "@/components/feedback/Backdrop";
import { usePathname, useSearchParams } from "next/navigation";


function Menu({ theme, isMobile }){
    const [ topLevel, setTopLevel ] = useState(null)
    const [ isOpen, setOpen ] = useState(false)

    const pathName = usePathname()
    const searchParams = useSearchParams()


    useEffect(() => {
        setOpen(false)

    }, [ pathName, searchParams ])


    useEffect(() => {
        async function fetchCategories(){
            return await fetch("/api/categories")
                .then(res => res.json())
                .then(res => {
                    const topLevel = res.categories.data.filter(category => category.parent_id === 0)
                    const sorted = topLevel.sort((a, b) => a.sort_order - b.sort_order)
                    
                    setTopLevel(sorted)
                })
                .catch(err => console.error(err)
            )
        }

        fetchCategories();

    }, [])



    return(
        <>
            <div className={Style.block}>
                <button 
                    type="button" 
                    className={clsx(Style.btn, Style[theme], isMobile && Style.mobile)} 
                    onClick={() => setOpen(!isOpen)}
                >
                    <span className={Style.text}>
                        Categories
                    </span>

                    {isOpen ? (
                        <IconClose className={Style.icon} />
                    ):(
                        <>
                            <svg id="icon-menu" viewBox="0 0 24 24" className={Style.icon}>
                                <path d="M16.594 8.578l1.406 1.406-6 6-6-6 1.406-1.406 4.594 4.594z"></path>
                            </svg>
                        </>
                    )}
                </button>

                <menu className={clsx(Style.dropdown, isOpen && Style.open)}>
                    <li className={Style.heading}>
                        All Categories
                    </li>

                    {topLevel && topLevel.map((category) => {
                        if( category?.category_id === 25 || !category.is_visible ) return null;

                        return(
                            <li className={Style.item} key={category.category_id}>
                                <Link 
                                    href={createCategoryURL({ url_path: category?.url?.path, id: category?.category_id })} 
                                    className={Style.link}
                                >
                                    {category?.name}
                                </Link>
                            </li>
                        )
                    })}
                </menu>
            </div>
            

            <Backdrop 
                updateOverlay={() => setOpen(false)}
                className={Style.backdrop} 
                {...{ show: isOpen }} 
                shouldBlur={false}
            />
        </>
    )
}

export default React.memo(Menu);